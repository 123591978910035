
import {
  defineComponent,
  onMounted,
  watch,
  ref,
  reactive,
  toRefs,
  toRef,
} from "vue";
import SatSearchInput from "@/components/SatSearchInput.vue";
import { IStadium, StadiumQueryDto, TeamQueryDto } from "@/_modules/types";
import { SelectTypes } from "ant-design-vue/lib/select";
import { LoadingOutlined } from "@ant-design/icons-vue";
import SatIcon from "@/components/SatIcon.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { Helpers } from "@/_modules/helpers";

import useStadiumRepositories from "@/_composables/useStadiumRepositories";
export default defineComponent({
  components: {
    SatSearchInput,
    SatIcon,

    LoadingOutlined,
  },
  setup() {
    const { t } = useI18n({
      useScope: "global",
    });
        const helpers = Helpers;

    const store = useStore();
    const state = reactive({
      currentPage: 1,
      lastPage: 1,
      query: { page: 1, size: 10 } as StadiumQueryDto,
      stadiums: [] as any,
      filtered: 0,
    });
    const loading = ref(false);
    const visibleMoreSection = ref(false);
    // const { repositories: stadiums } = useStadiumRepositories(ref(reactive({page: state.currentPage, size: 10})))

    const { getStadiumRepositories } = useStadiumRepositories();
    const fetchStadium = async (params: object) => {
      const stadium = await getStadiumRepositories(params);
      if (stadium) {
        const { data, currentPage, lastPage } = stadium?.stadium;
        state.stadiums = [...state.stadiums, ...data];
        state.currentPage = currentPage;
        state.lastPage = lastPage;
      }
    };
    onMounted(async () => {
      loading.value = true;
      await fetchStadium({ page: 1, size: 10 });
      loading.value = false;
    });

    const handleSearch = async (search: any | null) => {
      if (search?.name) {
        const params = {
          ...search,
          page: state.currentPage,
          size: 10,
        };
        state.stadiums = [];

        await fetchStadium(params);
      } else {
        state.stadiums = [];
        await fetchStadium(search);
      }
    };

    const loadMoreStadiums = async () => {
      visibleMoreSection.value = false;
      state.currentPage = state.currentPage + 1;
      if (state.currentPage <= state.lastPage) {
        loading.value = true;
        await fetchStadium({ page: state.currentPage, size: 10 });
        visibleMoreSection.value = true;
        loading.value = false;
      }
    };
    const handleFilterCategory = (value: string) => {
      state.filtered = Number(value);
    };
    return {
      t,
      store,
      // filtered: toRef(state, 'filtered'),
      handleFilterCategory,
      ...toRefs(state),
      loadMoreStadiums,
      loading,
      handleSearch,
      helpers
    };
  },
});
